import React, { FC } from 'react'
import { Button } from '@revolut/ui-kit'

import { HtmlStringComponent } from '../Controls/common'
import { HtmlString, BottomPromptView } from '../../types'
import { useIsWidgetMode } from '../../providers'

import { BoxStyled, TextStyled, InnerBoxWrapperStyled } from './styled'

const PRIMARY_BUTTON_WIDTH = '343px'

type Props = {
  primaryActionDisabled?: boolean
  secondaryActionsDisabled?: boolean
  nextText?: string
  actions: BottomPromptView['actions']
  isPrimaryButtonVisible?: boolean
  termsAndConditions?: HtmlString | null
  onClick: (actionId?: string, isValueValid?: boolean) => void
  onClose?: () => void
}

export const PRIMARY_BUTTON_TESTID = 'primary-button-testid'
export const TERMS_TEXT_TESTID = 'terms-text-testid'

const FlowActionButtons: FC<Props> = ({
  primaryActionDisabled = false,
  secondaryActionsDisabled = false,
  nextText,
  actions,
  isPrimaryButtonVisible = true,
  termsAndConditions,
  onClick,
  onClose,
}) => {
  const isWidgetMode = useIsWidgetMode()

  const [primaryButton, ...secondaryButtons] = actions
  const primaryButtonTitle = primaryButton ? primaryButton.title : nextText

  return (
    <BoxStyled>
      <InnerBoxWrapperStyled
        width={isWidgetMode ? 'auto' : PRIMARY_BUTTON_WIDTH}
        maxWidth={PRIMARY_BUTTON_WIDTH}
      >
        {termsAndConditions && (
          <TextStyled data-testid={TERMS_TEXT_TESTID}>
            <HtmlStringComponent htmlString={termsAndConditions} />
          </TextStyled>
        )}

        {isPrimaryButtonVisible && (
          <Button
            data-testid={PRIMARY_BUTTON_TESTID}
            elevated
            disabled={primaryActionDisabled}
            use={primaryButton?.hyperlink ? 'a' : 'button'}
            href={primaryButton?.hyperlink}
            onClick={() => onClick(primaryButton?.id)}
          >
            {primaryButtonTitle || 'Continue'}
          </Button>
        )}

        {secondaryButtons.map(secondaryButton => (
          <Button
            key={secondaryButton.id}
            use={secondaryButton.hyperlink ? 'a' : 'button'}
            variant="secondary"
            marginTop="s-16"
            disabled={secondaryActionsDisabled}
            href={secondaryButton.hyperlink}
            onClick={() =>
              secondaryButton.dismissOnSelect ? onClose?.() : onClick(secondaryButton.id)
            }
          >
            {secondaryButton.title || 'Continue'}
          </Button>
        ))}
      </InnerBoxWrapperStyled>
    </BoxStyled>
  )
}

export default FlowActionButtons
