import React, { useEffect } from 'react'
import IMask, { InputMask } from 'imask'

const transformPatternToIMaskFormat = (pattern: string) => {
  const result: string[] = []
  let inGroup: boolean = false

  for (const char of pattern) {
    switch (char) {
      case '[':
        inGroup = true
        break

      case ']':
        inGroup = false
        break

      case '0':
        if (inGroup) result.push('0')
        else result.push('\\0')
        break

      case '9':
        if (inGroup) result.push('[0]')
        else result.push('9')
        break

      case 'A':
        if (inGroup) result.push('a')
        else result.push('A')
        break

      case 'a':
        if (inGroup) result.push('[a]')
        else result.push('\\a')
        break

      case '-':
        if (inGroup) result.push('[_]')
        else result.push('-')
        break

      default:
        result.push(char)
    }
  }

  return result.join('')
}

export const useTextMask = (ref: React.MutableRefObject<any>, pattern?: string) => {
  useEffect(() => {
    let mask: InputMask

    if (pattern) {
      mask = IMask(ref.current, {
        mask: transformPatternToIMaskFormat(pattern ?? ''),
        definitions: {
          _: /[a-zA-Z0-9]/,
        },
        blocks: {
          '…': {
            repeat: Infinity,
            mask: /./,
          },
        },
      })
    }

    return () => {
      if (mask) mask.destroy()
    }
  }, [pattern, ref])
}
