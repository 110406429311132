import React, { FC } from 'react'
import { Group, ItemSkeleton, Subheader, VStack } from '@revolut/ui-kit'
import { EntitiesListItem, EntityType } from '../../../types'
import { EntityItem } from '../common/Entities/EntityItem'
import { useEntities } from '../common/Entities/useEntities'

type Props = EntitiesListItem & {
  openEntityDetails?(entityId: string, entityType: EntityType): void
}

const EntitiesList: FC<Props> = ({ id, title, entityType, openEntityDetails }) => {
  const { enabled, loading, entities } = useEntities(id, entityType)

  if (!enabled) return null
  if (loading) return <ItemSkeleton />

  const entityDetailsSupported = typeof openEntityDetails === 'function'

  const onEntityClick = (entityId: string) => {
    openEntityDetails?.(entityId, entityType)
  }

  return (
    <VStack>
      {title && (
        <Subheader>
          <Subheader.Title>{title}</Subheader.Title>
        </Subheader>
      )}
      <Group>
        {entities.map(entity => (
          <EntityItem
            key={entity.id}
            entity={entity}
            onClick={entityDetailsSupported ? onEntityClick : undefined}
          />
        ))}
      </Group>
    </VStack>
  )
}

export default EntitiesList
