import React, { FC } from 'react'
import { Header, Spacer, Text, TextButton, VStack } from '@revolut/ui-kit'

import { DESCRIPTION_GRID_AREA as UI_KIT_DESCRIPTION_GRID_AREA } from '@revolut/ui-kit/types/dist/components/HeaderBase/HeaderBaseDescription'
import { HtmlStringComponent } from '../Controls/common'
import { EntityType, FormView } from '../../types'
import { Actions as UseFormActions } from '../useFlowPage'
import FlowActionButtons from '../FlowActionButtons'

import useControlItem from './useControlItem'
import { SecondaryDescription } from '../Controls/SecondaryDescription'
import { isHTML } from '../helpers'

const DESCRIPTION_GRID_AREA: typeof UI_KIT_DESCRIPTION_GRID_AREA = 'description'

export type Props = {
  isTransition: boolean
  view: FormView
  changeViewItemValues: UseFormActions['changeViewItemValues']
  setDataFetching: (state: boolean) => void
  isViewDataFetching: boolean
  isFlowDone: boolean
  backHidden: boolean
  onActionButtonClick: (actionId?: string) => void
  openEntityDetails?: (entityId: string, entityType: EntityType) => void
  openTransactionDetails?: (transactionId: string) => void
  onBack: () => void
}

const ControlsView: FC<Props> = ({
  isTransition,
  view,
  changeViewItemValues,
  setDataFetching,
  isViewDataFetching,
  isFlowDone,
  backHidden,
  onBack,
  onActionButtonClick,
  openEntityDetails,
  openTransactionDetails,
}) => {
  const {
    items,
    isPrimaryButtonDisabled,
    isPrimaryButtonVisible,
    handleActionClick,
  } = useControlItem({
    changeViewItemValues,
    isTransition,
    view,
    hasCustomActions: view.actions.length > 0,
    setDataFetching,
    openEntityDetails,
    openTransactionDetails,
    onActionButtonClick,
  })

  const termsAndConditions = view?.footer
  const { headerAction } = view

  const secondaryActionsDisabled = isViewDataFetching || isFlowDone
  const primaryActionDisabled = secondaryActionsDisabled || isPrimaryButtonDisabled

  return (
    <>
      <Header variant="item">
        {!backHidden && (
          <Header.BackButton onClick={onBack} data-testid="button-back-testid" />
        )}
        {headerAction && (
          <Header.Actions>
            <TextButton
              use={headerAction.hyperlink ? 'a' : 'button'}
              href={headerAction.hyperlink}
              onClick={() => handleActionClick(headerAction.id, true)}
            >
              {headerAction.title}
            </TextButton>
          </Header.Actions>
        )}
        <Header.Title use="h1">{view.title}</Header.Title>
        <Description view={view} />
      </Header>
      {items}
      <Spacer />
      <FlowActionButtons
        isPrimaryButtonVisible={isPrimaryButtonVisible}
        primaryActionDisabled={primaryActionDisabled}
        secondaryActionsDisabled={secondaryActionsDisabled}
        onClick={handleActionClick}
        termsAndConditions={termsAndConditions}
        nextText={view.nextText}
        actions={view.actions}
      />
    </>
  )
}

const Description = ({ view }: { view: FormView }) => {
  const secondaryDescription = view.secondaryDescription ? (
    <SecondaryDescription secondaryDescription={view.secondaryDescription} />
  ) : null

  const isDescriptionVisible = view.subtitle || view.subtitleHtml || secondaryDescription

  if (!isDescriptionVisible) {
    return null
  }

  if (view.subtitleHtml && isHTML(view.subtitleHtml.value)) {
    return (
      <Text
        style={{ gridArea: DESCRIPTION_GRID_AREA }}
        display="block"
        variant="caption"
        mt="s-8"
        color="foreground"
      >
        <VStack space="s-16">
          <HtmlStringComponent htmlString={view.subtitleHtml} />
          {secondaryDescription}
        </VStack>
      </Text>
    )
  }

  return (
    <Header.Description>
      <VStack space="s-16">
        {view.subtitleHtml ? view.subtitleHtml.value : view.subtitle}
        {secondaryDescription}
      </VStack>
    </Header.Description>
  )
}

export default ControlsView
