import { useQuery } from 'react-query'
import { useApi, useFlowId } from '../../../../providers'
import { entitiesQueryKey } from './constants'
import { Entity, EntityType } from '../../../../types'

type EntitiesHook = {
  enabled: boolean
  entities: Entity[]
  loading: boolean
  hasError: boolean
  refetch: VoidFunction
}

export function useEntities(itemId: string, entityType?: EntityType): EntitiesHook {
  const { getEntities } = useApi()
  const flowId = useFlowId()

  const { data, isFetching, status, refetch } = useQuery(
    // entityType is embedded into query key in order to enable a dynamic entities preview in BO
    [entitiesQueryKey, flowId, entityType, itemId],
    () => getEntities?.(flowId, itemId),
    { enabled: !!getEntities },
  )

  return {
    enabled: !!getEntities,
    entities: data ?? [],
    loading: isFetching,
    hasError: status === 'error',
    refetch,
  }
}
