import { FC } from 'react'
import { TextButton } from '@revolut/ui-kit'
import React from 'react'

type Props = {
  label: string
  onClick(): void
}

export const SecondaryDescriptionButton: FC<Props> = ({ label, onClick }) => {
  return <TextButton onClick={onClick}>{label}</TextButton>
}
