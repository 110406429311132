import React, { useState, useEffect } from 'react'
import { omit } from 'lodash'
import { format, isToday } from 'date-fns'
import {
  Avatar,
  Box,
  Group,
  Item,
  Search,
  CheckboxGroup,
  Checkbox,
  Flex,
  Spinner,
} from '@revolut/ui-kit'
import { MoneyValue, Transaction, TransactionInputItem, TransactionsValue } from 'types'
import {
  includesSubstringCaseInsensitive,
  moneyValueToReadableValue,
} from '../../helpers'
import { useMoveToTheNextView } from '../../../providers'
import { FALLBACK_CURRENCIES } from '../common/constants/fallbackCurrencies'
import { useDebouncedSearch } from '../common/hooks/useDebouncedSearch'
import { useTransactionsLegacy } from './useTransactionsLegacy'
import { ItemIntersectionSkeleton } from './ItemIntersectionSkeleton'

export const SPINNER_TESTID = 'spinner-testid'

const searchTransactions = (transactions: Transaction[], filterValue: string) =>
  transactions.filter(transaction =>
    includesSubstringCaseInsensitive(transaction.description, filterValue),
  )

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp)
  return isToday(date) ? format(date, 'HH:mm') : format(date, 'dd/MM/yyyy HH:mm')
}

const getCurrencyLabel = (moneyValue: MoneyValue) => {
  const currencyDetails = FALLBACK_CURRENCIES.find(
    ({ code }) => code === moneyValue.currency,
  )
  if (!currencyDetails) return ''
  const readableValue = moneyValueToReadableValue(moneyValue, currencyDetails)
  return `${readableValue} ${currencyDetails?.symbol ?? ''}`
}

type Props = TransactionInputItem & {
  disabled: boolean
  changeValue: (value: TransactionsValue) => void
}

const TransactionInputLegacy = ({
  disabled,
  value,
  searchHint,
  changeValue,
  content: { properties: propertyFilters },
  maxSelected,
  style,
}: Props) => {
  const moveToTheNextView = useMoveToTheNextView()

  const [isMoveToTheNextViewPending, setIsMoveToTheNextViewPending] = useState(false)

  const {
    transactions,
    fetchNextTransactionsPage,
    isNextTransactionsPageFetching,
    isTransactionsFetching,
    hasNextPage,
  } = useTransactionsLegacy(propertyFilters)

  const { filterInputValue, filteredItems, debouncedSearch } = useDebouncedSearch<
    Transaction
  >(transactions, searchTransactions)

  const isDisplayIntersectionSkeleton =
    hasNextPage &&
    !isNextTransactionsPageFetching &&
    filterInputValue === '' &&
    filteredItems.length === transactions.length &&
    filteredItems.length > 10

  const isContinueButtonStyle = style === 'SINGLE_SELECTION'

  const handleContinueStyleItemClick = (id: string) => {
    changeValue({
      transactions: [transactions.find(transaction => transaction.id === id)].map(item =>
        omit(item, ['avatar']),
      ),
    })
    setIsMoveToTheNextViewPending(true)
  }

  useEffect(() => {
    // Do move after the state got updated.
    if (isMoveToTheNextViewPending) {
      moveToTheNextView()
      setIsMoveToTheNextViewPending(false)
    }
  }, [isMoveToTheNextViewPending, moveToTheNextView])

  if (isTransactionsFetching && !transactions.length) {
    return (
      <Flex height="100%" alignItems="center" justifyContent="center">
        <Spinner data-testid={SPINNER_TESTID} color="blue" size={120} />
      </Flex>
    )
  }

  return (
    <>
      <Box mb="s-16">
        <Search
          value={filterInputValue}
          placeholder={searchHint || 'Search transaction'}
          onChange={debouncedSearch}
        />
      </Box>
      <Group>
        {isContinueButtonStyle ? (
          filteredItems.map(({ id, description, createdDate, amount, avatar }) => (
            <Item
              key={id}
              use="button"
              variant="disclosure"
              onClick={() => handleContinueStyleItemClick(id)}
            >
              <Item.Avatar>
                {avatar ?? <Avatar uuid={id} useIcon="ArrowRightLeft" />}
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{description}</Item.Title>
                <Item.Description>{formatDate(createdDate)}</Item.Description>
              </Item.Content>
              <Item.Side>
                <Item.Value>{getCurrencyLabel(amount)}</Item.Value>
              </Item.Side>
            </Item>
          ))
        ) : (
          <CheckboxGroup
            value={value?.transactions?.map(({ id }) => id) ?? []}
            disabled={disabled}
            onChange={ids => {
              if (ids.length > maxSelected) return
              changeValue({
                transactions: transactions
                  .filter(({ id }) => ids.includes(id))
                  .map(item => omit(item, ['avatar'])),
              })
            }}
          >
            {group =>
              filteredItems.map(({ id, description, createdDate, amount, avatar }) => (
                <Item key={id} use="label">
                  <Item.Prefix>
                    <Checkbox {...group.getInputProps({ value: id })} />
                  </Item.Prefix>
                  <Item.Avatar>
                    {avatar ?? <Avatar uuid={id} useIcon="ArrowRightLeft" />}
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>{description}</Item.Title>
                    <Item.Description>{formatDate(createdDate)}</Item.Description>
                  </Item.Content>
                  <Item.Side>
                    <Item.Value>{getCurrencyLabel(amount)}</Item.Value>
                  </Item.Side>
                </Item>
              ))
            }
          </CheckboxGroup>
        )}

        {isDisplayIntersectionSkeleton && (
          <ItemIntersectionSkeleton onIntersect={fetchNextTransactionsPage} />
        )}
        {isNextTransactionsPageFetching && (
          <Flex height="100%" alignItems="center" justifyContent="center">
            <Spinner color="blue" size={60} />
          </Flex>
        )}
      </Group>
    </>
  )
}

export default TransactionInputLegacy
