import React from 'react'
import { StatusPopup, Button } from '@revolut/ui-kit'

import { StatusDialogView } from 'types'

import { useFollowLink } from '../useFollowLink'

const ErrorDialog = ({
  statusDialogView,
  onDialogClose,
}: {
  statusDialogView: StatusDialogView
  onDialogClose: () => void
}) => {
  const followLink = useFollowLink()
  const url = statusDialogView?.dialogActionHyperlink

  const onExit = () => {
    if (url) {
      followLink(url)
    } else {
      onDialogClose()
    }
  }

  return (
    <StatusPopup variant="error" open onClose={onExit}>
      <StatusPopup.Title>
        {statusDialogView?.title || 'Something went wrong'}
      </StatusPopup.Title>
      <StatusPopup.Description>
        {statusDialogView?.description || ''}
      </StatusPopup.Description>
      <StatusPopup.Actions>
        <Button variant="secondary" onClick={onExit}>
          {statusDialogView?.dialogActionCaption || 'Ok'}
        </Button>
      </StatusPopup.Actions>
    </StatusPopup>
  )
}

export default ErrorDialog
