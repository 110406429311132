import React, { FC, useState, useEffect } from 'react'
import { Group, ItemSkeleton } from '@revolut/ui-kit'
import {
  EntitiesSelectionItem,
  EntitiesSelectionItemValue,
  EntityType,
} from '../../../types'
import { useMoveToTheNextView } from '../../../providers'
import { useEntities } from '../common/Entities/useEntities'
import { EntityItem } from '../common/Entities/EntityItem'

type Props = EntitiesSelectionItem & {
  disabled: boolean
  changeValue(value: EntitiesSelectionItemValue): void
  openEntityDetails?(entityId: string, entityType: EntityType): void
}

const EntitiesSelection: FC<Props> = ({
  value,
  changeValue: onChange,
  disabled,
  id,
  entityType,
  openEntityDetails,
  maxSelectedOptions,
  style,
}) => {
  const { enabled, loading, entities } = useEntities(id, entityType)

  const moveToTheNextView = useMoveToTheNextView()
  const [isMoveToTheNextViewPending, setIsMoveToTheNextViewPending] = useState(false)

  const handleItemSelection = (itemId: string, selected: boolean) => {
    const selectedOptions: string[] = value?.optionIds ?? []
    if (selected) {
      if (selectedOptions.length >= maxSelectedOptions) return
      onChange({ optionIds: [...selectedOptions, itemId] })
    } else {
      onChange({ optionIds: [...selectedOptions.filter(id => id !== itemId)] })
    }
  }

  const entityDetailsSupported = typeof openEntityDetails === 'function'
  const isContinueButtonStyle = style === 'SINGLE_SELECTION'
  const hasClickHandler = isContinueButtonStyle || entityDetailsSupported

  const onEntityClick = (entityId: string) => {
    if (isContinueButtonStyle) {
      handleItemSelection(entityId, true)
      setIsMoveToTheNextViewPending(true)
    } else if (entityDetailsSupported) {
      openEntityDetails?.(entityId, entityType)
    }
  }

  useEffect(() => {
    // Do move after the state got updated.
    if (isMoveToTheNextViewPending) {
      moveToTheNextView()
      setIsMoveToTheNextViewPending(false)
    }
  }, [isMoveToTheNextViewPending, moveToTheNextView])

  if (!enabled) return null
  // TODO FRMBLDR-678 should we support error state with retry? Same for empty array
  if (loading) return <ItemSkeleton />

  return (
    <Group>
      {entities.map(entity => (
        <EntityItem
          selected={!!value && value.optionIds.some(id => entity.id === id)}
          disabled={disabled}
          key={entity.id}
          entity={entity}
          isContinueButtonStyle={isContinueButtonStyle}
          onSelect={!isContinueButtonStyle ? handleItemSelection : undefined}
          onClick={hasClickHandler ? onEntityClick : undefined}
        />
      ))}
    </Group>
  )
}

export default EntitiesSelection
