import { useCallback } from 'react'
import { useQueryClient } from 'react-query'

import { Validation, TextInputValidationType } from '../types'

import { useApi } from '../providers'

export type ValidationResult = {
  isValid: boolean
  message?: string
}

const validateRegex = (value: string, pattern: string) =>
  value?.length && new RegExp(pattern).test(value)

export const useTextInputValidation = (formId: string, viewId: string) => {
  const api = useApi()
  const queryClient = useQueryClient()

  const validateTextInput = useCallback(
    async (
      itemId: string,
      required: boolean,
      value?: string,
      validations?: Validation[],
    ): Promise<ValidationResult> => {
      if (!value) return { isValid: !required }
      if (!validations) return { isValid: true }

      for await (const validation of validations) {
        if (
          (validation.type === TextInputValidationType.TEXT || !validation.type) &&
          !validateRegex(value, validation.pattern)
        ) {
          return {
            isValid: false,
            message: validation.message,
          }
        }

        if (validation.type === TextInputValidationType.EXTERNAL) {
          const validationResponse = await queryClient.fetchQuery(
            ['validateItem', formId, viewId, itemId, value],
            () => api.validateItem(formId, viewId, itemId, value),
          )
          if (!validationResponse.isValid) {
            return validationResponse
          }
        }
      }
      return { isValid: true }
    },
    [api, formId, viewId, queryClient],
  )

  return validateTextInput
}
