import { EntitiesSelectionItemValue, TransactionsValue } from 'library'
import { isEmpty } from 'lodash'
import validator from 'validator'

import { validateAddressValue } from '../Controls/AddressInput/helpers/validateAddressValue'
import { FlowViewItemType } from '../../appConstants'
import {
  MoneyValue,
  PhoneValue,
  FilesValue,
  CardSelectionValue,
  FlowViewItemDynamicSingle,
  SingleSelectionValue,
  MultiSelectionValue,
  AddressInputValue,
  RegexValidation,
  EditableListItemValue,
} from '../../types'
import { useTextInputValidation } from '../useTextInputValidation'
import { GroupedViewItem } from './utils'

export const useValidateValue = (formId: string, viewId: string) => {
  const validateTextInput = useTextInputValidation(formId, viewId)

  const validateValue = async (groupedItem: GroupedViewItem) => {
    switch (groupedItem.type) {
      case 'GROUP': {
        const { group } = groupedItem

        return group.items.some(item => {
          if (!item.required) return true

          switch (item.type) {
            case FlowViewItemType.MultiChoiceLegacy:
            case FlowViewItemType.SingleChoiceLegacy:
              return item.value?.value === true
            case FlowViewItemType.SingleChoiceTextInputLegacy:
              return item.value?.selected && item.value?.content.value?.length
            default: {
              const _: never = item
              return _
            }
          }
        })
      }
      case 'SINGLE': {
        const item = groupedItem.item as FlowViewItemDynamicSingle
        const value = item.value

        // value.value property per API design
        const innerValue = (value as any)?.value
        const isNotRequired = !item.required

        if (isNotRequired && !innerValue && !value) {
          return true
        }

        if (!value) {
          return false
        }

        switch (item.type) {
          case FlowViewItemType.AddressInput:
            return (
              Object.values(validateAddressValue(value as AddressInputValue)).filter(
                Boolean,
              ).length === 0
            )
          case FlowViewItemType.CardInput:
            return validator.isCreditCard(innerValue)

          case FlowViewItemType.DateInput:
            return validator.isISO8601(innerValue)

          case FlowViewItemType.TimeInput:
            return /^([2][0-3]|[01]?[0-9]):[0-5][0-9]$/.test(innerValue)

          case FlowViewItemType.CameraImage:
          case FlowViewItemType.FilesUpload:
            return !isEmpty((value as FilesValue).files)

          case FlowViewItemType.MoneyInput:
            return (
              Boolean((value as MoneyValue).currency) &&
              !isNaN((value as MoneyValue).amount)
            )

          case FlowViewItemType.PhoneInput:
            return (
              Boolean((value as PhoneValue).code) &&
              /^[0-9]{5,11}$/.test((value as PhoneValue).number)
            )

          case FlowViewItemType.TransactionInput:
            return (value as TransactionsValue).transactions.length > 0

          case FlowViewItemType.EntitiesSelection:
            return (value as EntitiesSelectionItemValue).optionIds.length > 0

          case FlowViewItemType.TextInput: {
            const validationResult = await validateTextInput(
              item.id,
              item.required,
              innerValue,
              item.validations,
            )
            return validationResult.isValid
          }

          case FlowViewItemType.YesNoInput:
            return typeof innerValue === 'boolean'

          case FlowViewItemType.CardSelection:
            return (value as CardSelectionValue).cards.length > 0

          case FlowViewItemType.SignatureInput:
            return (
              typeof innerValue === 'string' &&
              typeof (item.validations[0] as RegexValidation).pattern === 'string' &&
              innerValue.match(
                new RegExp((item.validations[0] as RegexValidation).pattern),
              )
            )

          case FlowViewItemType.SingleSelection: {
            const { optionId, customOption } = value as SingleSelectionValue
            const customOptionIsValid =
              typeof customOption === 'string' ? customOption.length > 0 : true
            return optionId !== undefined && customOptionIsValid
          }

          case FlowViewItemType.MultiSelection: {
            const { optionIds, customOption } = value as MultiSelectionValue

            const customOptionIsValid =
              typeof customOption === 'string' ? customOption.length > 0 : true
            const requiredAllOptionsIsValid =
              !item.requiredAllOptionsSelected || optionIds.length >= item.options.length

            return (
              optionIds !== undefined &&
              optionIds.length > 0 &&
              customOptionIsValid &&
              requiredAllOptionsIsValid
            )
          }

          case FlowViewItemType.EditableList: {
            const { items: itemsValues } = value as EditableListItemValue
            const { items: itemsDefinitions } = item
            const items = itemsValues.map(item => ({
              ...item,
              required: itemsDefinitions.find(({ id }) => id === item.id)?.required,
            }))

            return (
              isNotRequired ||
              (items.length > 0 && items.every(item => !item.required || !!item.value))
            )
          }

          default:
            return Boolean(innerValue)
        }
      }

      default: {
        const _: never = groupedItem
        return _
      }
    }
  }

  return validateValue
}
