import { FC } from 'react'
import { BottomSheet, Button, Group, Header, Item, Link, Text } from '@revolut/ui-kit'
import React from 'react'
import { FormViewSecondaryDescriptionItem, HtmlString } from '../../../types'
import { isHTML } from '../../helpers'
import { HtmlStringComponent } from '../common'

type Props = {
  open: boolean
  title: string
  buttonTitle: string
  items: FormViewSecondaryDescriptionItem[]
  subtitle?: string
  subtitleHtml?: HtmlString
  onClose(): void
}

export const SecondaryDescriptionDialog: FC<Props> = ({
  title,
  subtitle,
  subtitleHtml,
  buttonTitle,
  items,
  open,
  onClose,
}) => {
  const hasHTML = subtitleHtml && isHTML(subtitleHtml.value)

  return (
    <BottomSheet onClose={onClose} open={open}>
      <Header>
        <Header.Title>{title}</Header.Title>
        {!hasHTML && (
          <Header.Subtitle>
            {subtitleHtml ? subtitleHtml.value : subtitle}
          </Header.Subtitle>
        )}
      </Header>
      {hasHTML && <HtmlStringComponent htmlString={subtitleHtml} />}
      <Group>
        {items.map(item => (
          <Item key={item.id} useIcon={item.icon?.name} iconColor="foreground">
            <Item.Content>
              <Item.Title>{item.title}</Item.Title>
              {!!item.description && (
                <Item.Description>{item.description}</Item.Description>
              )}
              {!!item.link && !!item.link.hyperlink && (
                <Link href={item.link.hyperlink}>
                  <Text variant="caption">{item.link.title}</Text>
                </Link>
              )}
            </Item.Content>
          </Item>
        ))}
      </Group>
      <BottomSheet.Actions>
        <Button elevated onClick={onClose}>
          {buttonTitle}
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
