import React from 'react'

import {
  Group,
  Item,
  Icon,
  Bullet,
  HStack,
  VStack,
  Text,
  Link,
  Subheader,
  ActionButton,
  Grid,
} from '@revolut/ui-kit'

import { useFollowLink } from '../../useFollowLink'
import { ListItem } from '../../../types'
import { DEFAULT_LIST_ICON } from '../../../appConstants'

type Props = ListItem

const List = ({ style, linkStyle = 'LINK', items, title }: Props) => {
  const followLink = useFollowLink()

  return (
    <>
      {title && <Subheader>{title}</Subheader>}
      <Group>
        {items.map(({ id, title, description, icon, link }, index) => (
          <Item
            key={id}
            variant={link && linkStyle === 'NAVIGATION' ? 'disclosure' : 'default'}
            use={link && linkStyle === 'NAVIGATION' ? 'button' : undefined}
            onClick={
              link && linkStyle === 'NAVIGATION'
                ? () => followLink(link.hyperlink)
                : undefined
            }
          >
            <Item.Avatar>
              <Grid placeItems="center" height="100%">
                {style === 'NUMBERED' && <Bullet>{index + 1}</Bullet>}
                {style === 'ICONIZED' && (
                  <Icon size={24} name={icon?.name ?? DEFAULT_LIST_ICON} />
                )}
              </Grid>
            </Item.Avatar>
            <Item.Content>
              <VStack>
                <Item.Title>{title}</Item.Title>
                {description && <Item.Description>{description}</Item.Description>}
                {link && linkStyle === 'LINK' && (
                  <Link href={link.hyperlink} mt="s-8">
                    <HStack align="center" space="s-8">
                      <Icon name="16/LinkExternal" size={16} />
                      <Text variant="caption" fontWeight={500}>
                        {link.title ?? link.hyperlink}
                      </Text>
                    </HStack>
                  </Link>
                )}
                {link && linkStyle === 'BUTTON' && (
                  <ActionButton use={Link} href={link.hyperlink} mt="s-8">
                    {link.title ?? link.hyperlink}
                  </ActionButton>
                )}
              </VStack>
            </Item.Content>
          </Item>
        ))}
      </Group>
    </>
  )
}

export default List
