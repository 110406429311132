import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { isAbsoluteURL } from './helpers'

export const useFollowLink = () => {
  const history = useHistory()

  const followLink = useCallback(
    (url: string) => {
      if (isAbsoluteURL(url)) {
        window.location.href = url
      } else {
        history.push(url)
      }
    },
    [history],
  )

  return followLink
}
