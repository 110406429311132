import { IconName } from '@revolut/ui-kit'
import * as Controls from './FlowPage/Controls'

export enum Route {
  Flow = '/:flowId',
  NotFound = '/not-found',
  NoCamera = '/no-camera',
}

export enum FlowState {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE',
}

export enum UploadState {
  Uploading = 'UPLOADING',
  UploadFailed = 'UPLOAD_FAILED',
}

export enum FlowViewType {
  Form = 'FORM',
  DeepLink = 'DEEPLINK',
  StatusDialog = 'STATUS_DIALOG',
  BottomPrompt = 'BOTTOM_PROMPT',
  Prompt = 'PROMPT',
}

export enum DialogType {
  Success = 'SUCCESS',
  Pending = 'PENDING',
  Error = 'ERROR',
  Exit = 'EXIT',
}

export enum FlowViewItemType {
  CameraImage = 'CAMERA_IMAGE',
  DateInput = 'DATE_INPUT',
  Document = 'DOCUMENT',
  FilesUpload = 'FILES_UPLOAD',
  Image = 'IMAGE',
  MoneyInput = 'MONEY_INPUT',
  MultiChoiceLegacy = 'MULTI_CHOICE',
  PhoneInput = 'PHONE_INPUT',
  SingleChoiceLegacy = 'SINGLE_CHOICE',
  SingleChoiceTextInputLegacy = 'SINGLE_CHOICE_TEXT_INPUT',
  StarRatingInput = 'STAR_RATING_INPUT',
  TextInfo = 'TEXT',
  TextInput = 'TEXT_INPUT',
  TimeInput = 'TIME_INPUT',
  YesNoInput = 'YES_NO_INPUT',
  CountryInput = 'COUNTRY_SELECTION',
  CurrencyInput = 'CURRENCY_SELECTION',
  TransactionInput = 'TRANSACTIONS_SELECTION',
  TransactionCell = 'TRANSACTION_CELL',
  List = 'LIST',
  CardSelection = 'CARD_SELECTION',
  CardCell = 'CARD_CELL',
  EntityCell = 'ENTITY_CELL',
  SignatureInput = 'SIGNATURE_INPUT',
  SingleSelection = 'SINGLE_SELECTION',
  MultiSelection = 'MULTI_SELECTION',
  TableItem = 'TABLE_ITEM',
  EntitiesSelection = 'ENTITIES_SELECTION',
  AddressInput = 'ADDRESS_INPUT',
  WidgetProgression = 'WIDGET_PROGRESSION',
  EntitiesList = 'ENTITIES_LIST',
  EditableList = 'EDITABLE_LIST',
  // Legacy:
  Chooser = 'CHOOSER',
  CardInput = 'CARD_INPUT',
}

export enum CameraImageSource {
  Rear = 'REAR',
  Front = 'FRONT',
}

export enum FlowViewItemImageContent {
  Lottie = 'LOTTIE',
  Static = 'STATIC',
}

export enum TextStyle {
  PrimaryText = 'PRIMARY_TEXT',
  GroupHeader = 'GROUP_HEADER',
}

export enum TransactionTypeIconCode {
  Cashback = 'CASHBACK',
  Cash = 'CASH',
  Fee = 'FEE',
  Transfer = 'TRANSFER',
  Card = 'CARD',
}

export enum MerchantIconCode {
  Health = 'HEALTH',
  General = 'GENERAL',
  Services = 'SERVICES',
  Travel = 'TRAVEL',
  Transport = 'TRANSPORT',
  Accommodation = 'ACCOMMODATION',
  Utilities = 'UTILITIES',
  Shopping = 'SHOPPING',
  Financial = 'FINANCIAL',
  Furniture = 'FURNITURE',
  Hardware = 'HARDWARE',
  Groceries = 'GROCERIES',
  Fuel = 'FUEL',
  Entertainment = 'ENTERTAINMENT',
  Software = 'SOFTWARE',
  Restaurants = 'RESTAURANTS',
  Advertising = 'ADVERTISING',
  Cash = 'CASH',
  Education = 'EDUCATION',
  Government = 'GOVERNMENT',
}

export type IconCode = TransactionTypeIconCode | MerchantIconCode

export const REVOLUT_WEBSITE = 'https://revolut.com'

export const staticItemTypes: Readonly<Array<FlowViewItemType>> = [
  FlowViewItemType.Document, // Can be dynamic (with value)
  FlowViewItemType.Image,
  FlowViewItemType.TextInfo,
  FlowViewItemType.List,
]

export const combinedItemTypes: Readonly<Array<FlowViewItemType>> = [
  FlowViewItemType.MultiChoiceLegacy,
  FlowViewItemType.SingleChoiceLegacy,
  FlowViewItemType.SingleChoiceTextInputLegacy,
]

export const fullWidthItemType: Readonly<Array<FlowViewItemType>> = [
  FlowViewItemType.CameraImage,
  FlowViewItemType.FilesUpload,
  FlowViewItemType.Image,
  FlowViewItemType.TextInfo,
]

export const mapItemTypeToElement = {
  [FlowViewItemType.CameraImage]: Controls.FilesUpload,
  [FlowViewItemType.CardInput]: Controls.CardInput,
  [FlowViewItemType.Chooser]: Controls.Chooser,
  [FlowViewItemType.DateInput]: Controls.DateInput,
  [FlowViewItemType.FilesUpload]: Controls.FilesUpload,
  [FlowViewItemType.Image]: Controls.Image,
  [FlowViewItemType.MoneyInput]: Controls.MoneyInput,
  [FlowViewItemType.MultiChoiceLegacy]: Controls.MultiChoiceLegacy,
  [FlowViewItemType.PhoneInput]: Controls.PhoneInput,
  [FlowViewItemType.SingleChoiceLegacy]: Controls.SingleChoiceLegacy,
  [FlowViewItemType.StarRatingInput]: Controls.StarRatingInput,
  [FlowViewItemType.TextInfo]: Controls.TextInfo,
  [FlowViewItemType.TextInput]: Controls.TextInput,
  [FlowViewItemType.TimeInput]: Controls.TimeInput,
  [FlowViewItemType.YesNoInput]: Controls.YesNoInput,
  [FlowViewItemType.CountryInput]: Controls.CountryInput,
  [FlowViewItemType.CurrencyInput]: Controls.CurrencyInput,
  [FlowViewItemType.TransactionInput]: Controls.TransactionInput,
  [FlowViewItemType.TransactionCell]: Controls.TransactionCell,
  [FlowViewItemType.List]: Controls.List,
  [FlowViewItemType.CardSelection]: Controls.CardSelection,
  [FlowViewItemType.SignatureInput]: Controls.SignatureInput,
  [FlowViewItemType.SingleSelection]: Controls.SingleSelection,
  [FlowViewItemType.MultiSelection]: Controls.MultiSelection,
  [FlowViewItemType.CardCell]: Controls.CardCell,
  [FlowViewItemType.EntityCell]: Controls.EntityCell,
  [FlowViewItemType.TableItem]: Controls.SectionDetails,
  [FlowViewItemType.EntitiesSelection]: Controls.EntitiesSelection,
  [FlowViewItemType.AddressInput]: Controls.AddressInput,
  [FlowViewItemType.WidgetProgression]: Controls.WidgetProgressionCell,
  [FlowViewItemType.EntitiesList]: Controls.EntitiesList,
  [FlowViewItemType.EditableList]: Controls.EditableList,
}

export const mapMerchantIconCodeToCategoryIcon = {
  [MerchantIconCode.Accommodation]: 'Utilities',
  [MerchantIconCode.Advertising]: 'Bulb',
  [MerchantIconCode.Travel]: 'Transfers',
  [MerchantIconCode.Cash]: 'Cash',
  [MerchantIconCode.Education]: 'Child-allowance',
  [MerchantIconCode.Entertainment]: 'Entertainment',
  [MerchantIconCode.Financial]: 'Investment',
  [MerchantIconCode.Fuel]: 'Flag',
  [MerchantIconCode.Furniture]: 'Cash-alt',
  [MerchantIconCode.General]: 'General',
  [MerchantIconCode.Government]: 'Briefcase',
  [MerchantIconCode.Groceries]: 'Groceries',
  [MerchantIconCode.Hardware]: 'Laptop',
  [MerchantIconCode.Health]: 'Health',
  [MerchantIconCode.Restaurants]: 'Restaurants',
  [MerchantIconCode.Services]: 'Services',
  [MerchantIconCode.Shopping]: 'Shopping',
  [MerchantIconCode.Software]: 'Rocket',
  [MerchantIconCode.Transport]: 'Transport',
  [MerchantIconCode.Utilities]: 'Tools',
}

export const mapTransactionTypeIconCodeToIconUrl = {
  [TransactionTypeIconCode.Cashback]:
    'https://assets.revolut.com/assets/categories/Cashback@2x.png',
  [TransactionTypeIconCode.Cash]:
    'https://assets.revolut.com/assets/categories/Salary@2x.png',
  [TransactionTypeIconCode.Fee]: 'https://assets.revolut.com/assets/banks/Revolut.svg',
  [TransactionTypeIconCode.Transfer]:
    'https://assets.revolut.com/assets/categories/Transfers@2x.png',
  [TransactionTypeIconCode.Card]:
    'https://assets.revolut.com/assets/categories/Card@2x.png',
}

export const DEFAULT_FRACTION_DIGITS = 2

// Legacy, to remove after BE-driven transaction search release
export enum PropertyFilterType {
  Eq = 'EQ',
  In = 'IN',
  Between = 'BETWEEN',
  Gt = 'GT',
  Lt = 'LT',
}

export enum FilterValueType {
  String = 'STRING',
  Boolean = 'BOOLEAN',
  Int32 = 'INT32',
  Int64 = 'INT64',
  DateTime = 'DATE_TIME',
  Date = 'DATE',
  Time = 'TIME',
}

export enum DateTimeExpressionType {
  Value = 'VALUE',
  Now = 'NOW',
}

export type ListItemStyle = 'NUMBERED' | 'ICONIZED' | 'PARAGRAPH'
export type ListLinkStyle = 'LINK' | 'BUTTON' | 'NAVIGATION'

export const DEFAULT_LIST_ICON: IconName = 'Document'

export const TRANSACTION_COUNT_TO_REQUEST = 120
export const TRANSACTION_PERIOD_DAYS_TO_SUBTRACT = 30
export const CHOICE_ELEMENT_AMOUNT_TO_DISPLAY_SEARCHBAR = 10
