import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { TextArea } from '@revolut/ui-kit'

import { useFlowId } from '../../../providers'
import { Shape, StringValue, Validation } from '../../../types'
import useScrollToRef from '../../useScrollToRef'
import { useTextInputValidation, ValidationResult } from '../../useTextInputValidation'
import { useTextMask } from '../../useTextMask'

type Props = {
  id: string
  viewId: string
  required: boolean
  value?: StringValue
  disabled: boolean
  textTransformation: 'DEFAULT' | 'UPPERCASE'
  hint: string
  changeValue: (value: string) => void
  isFlowSubmitted: boolean
  underlineText?: string
  mask?: string
  placeholder?: string
  validations?: Validation[]
  shape?: Shape
}

export const TEXTAREA_TESTID = 'textarea-testid'
const MAX_ROWS = 6

const TextInput: FC<Props> = ({
  id,
  viewId,
  required,
  value,
  disabled,
  textTransformation,
  hint,
  changeValue,
  isFlowSubmitted,
  underlineText,
  mask,
  placeholder,
  validations,
  shape,
}) => {
  const [validationResult, setValidationResult] = useState<ValidationResult>()

  const { ref, scroll } = useScrollToRef()
  const formId = useFlowId()
  const validateTextInput = useTextInputValidation(formId, viewId)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      changeValue(
        textTransformation === 'UPPERCASE'
          ? e.target.value.toUpperCase()
          : e.target.value,
      )
    },
    [changeValue, textTransformation],
  )

  useEffect(() => {
    if (!isFlowSubmitted) return

    const validate = async () => {
      setValidationResult(
        await validateTextInput(id, required, value?.value, validations),
      )
    }
    validate()
  }, [
    isFlowSubmitted,
    setValidationResult,
    validateTextInput,
    value,
    validations,
    id,
    required,
  ])

  useTextMask(ref, mask)

  const isInvalid = isFlowSubmitted && value && !validationResult?.isValid

  return (
    <TextArea
      data-testid={TEXTAREA_TESTID}
      ref={ref}
      label={hint || 'Type answer here'}
      placeholder={placeholder}
      value={value?.value || ''}
      onChange={handleChange}
      disabled={disabled}
      rows={shape?.linesCount ?? 1}
      maxRows={MAX_ROWS}
      autosize={shape?.expandable ?? true}
      onFocus={() => scroll()}
      onClear={() => changeValue('')}
      invalid={isInvalid}
      errorMessage={validationResult?.message}
      description={!isInvalid && underlineText}
    />
  )
}

export default TextInput
