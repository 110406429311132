import { useMemo, useState, useEffect, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useBoolean, useArray } from 'react-hanger/array'
import { has, isEmpty, last } from 'lodash'

import {
  isBusinessWebChatTicketIdResponse,
  isBusinessWebStaticFormResponse,
} from '../api/api'
import { isViewItemDynamic } from '../guards'
import {
  staticItemTypes,
  Route,
  REVOLUT_WEBSITE,
  FlowState,
  FlowViewType,
  FlowViewItemType,
} from '../appConstants'
import {
  Action,
  Flow,
  FormView,
  FlowViewItem,
  FlowViewItemDynamic,
  StatusDialogView,
  BottomPromptView,
  FlowView,
  PromptView,
} from '../types'
import { useIsWidgetMode, useApi } from '../providers'
import { useTransition } from './useTransition'

import { useCamera } from './useCamera'
import { useValidateAndSubmitFlow } from './useValidateAndSubmitFlow'

export type Values = {
  amountOfLoadedViews: number
  currentView?: FormView | StatusDialogView
  currentPrompt: BottomPromptView | PromptView | null
  hasPreviousView: boolean
  currentViewIndex?: number
  errorText?: string
  statusDialogView?: StatusDialogView
  history: FormView['id'][]
  isFetching: boolean
  isFlowDone: boolean
  isFlowNotFound: boolean
  isNoCamera: boolean
  isTransition: boolean
  isViewDataFetching: boolean
}

export type Actions = {
  selectViewAction: (actionId: string) => void
  changeViewItemValues: (values: {
    [itemId: string]: FlowViewItemDynamic['value']
  }) => void
  moveToTheNextView: () => void
  moveToThePreviousView: () => void
  hideCurrentPrompt: () => void
  setViewDataFetching: (state: boolean) => void
  abortFlow: () => void
}

export const UNABLE_LOAD_FLOW_TEXT = 'Unable to load the form.'
export const UNABLE_SUBMIT_TEXT = 'Unable to submit the form.'

const navigateToWebsite = () => window.location.assign(REVOLUT_WEBSITE)

export default function useFlowPage({
  flowId,
  onFlowComplete,
  onFlowAbort,
  onBackButtonClick,
  onFlowViewChange,
}: {
  flowId: string
  onFlowComplete?: (...args: any) => void
  onFlowAbort?: () => void
  onBackButtonClick?: () => void
  onFlowViewChange?: () => void
}): [Values, Actions] {
  const [flow, setFlow] = useState<Flow>()
  const [isFetching, setIsFetching] = useBoolean(true)
  const [errorText, setErrorText] = useState<string | undefined>()
  const [statusDialogView, setStatusDialogView] = useState<StatusDialogView>()
  const [isFlowDone, isFlowDoneActions] = useBoolean(false)
  const [isFlowNotFound, setIsFlowNotFound] = useBoolean(false)
  const [isNoCamera, setIsNoCamera] = useBoolean(false)
  const isCameraSupport = useCamera()
  const [isViewDataFetching, setViewDataFetching] = useState(false)
  const [currentView, setCurrentView] = useState<FormView | StatusDialogView>()
  const [currentPrompt, setCurrentPrompt] = useState<
    BottomPromptView | PromptView | null
  >(null)
  const [history, historyActions] = useArray<FormView['id']>([])
  const isWidgetMode = useIsWidgetMode()
  const api = useApi()
  const validateAndSubmitFlow = useValidateAndSubmitFlow()
  const { search: queryString } = useLocation()
  const { push: navigate } = useHistory()

  const [{ isTransition }, { setTransition }] = useTransition()

  const getFormFlowViews = (item?: Flow): FormView[] | undefined =>
    item?.views?.filter((view): view is FormView => view.type === FlowViewType.Form)

  const getDynamicItems = (items: FlowViewItem[]) =>
    items.filter(
      (item: FlowViewItem): item is FlowViewItemDynamic =>
        !staticItemTypes.includes(item.type),
    )

  const isItemInContinueButtonMode = (item?: FlowViewItem) =>
    ((item?.type === FlowViewItemType.TransactionInput ||
      item?.type === FlowViewItemType.EntitiesSelection) &&
      item?.style === 'SINGLE_SELECTION') ||
    (item?.type === FlowViewItemType.SingleSelection && item?.style === 'CONTINUE_BUTTON')

  const setFlowView = useCallback(
    (viewId: string, views: FlowView[]) => {
      const viewIndex = views?.findIndex(view => view.id === viewId) ?? 0
      const view = views?.[viewIndex]
      onFlowViewChange?.()
      switch (view?.type) {
        case FlowViewType.Form: {
          setCurrentView(view)
          setCurrentPrompt(null)
          break
        }
        case FlowViewType.StatusDialog:
          isFlowDoneActions.setTrue()
          setStatusDialogView(view)
          setCurrentPrompt(null)
          historyActions.clear()
          break
        case FlowViewType.DeepLink:
          window.location.assign(view.webLink)
          break
        case FlowViewType.Prompt:
        case FlowViewType.BottomPrompt: {
          const previousFormView = views
            ?.slice(0, viewIndex)
            ?.reverse()
            ?.find(
              (previousView): previousView is FormView =>
                previousView.type === FlowViewType.Form,
            )
          setCurrentView(previousFormView)
          setCurrentPrompt(view)
          break
        }
      }
    },
    [onFlowViewChange, isFlowDoneActions, historyActions],
  )

  useEffect(() => {
    async function fetchFlow() {
      setTransition()

      try {
        const fetchedFlow = await api.loadFlow(flowId, queryString)

        if (!fetchedFlow || isEmpty(fetchedFlow.views)) {
          isWidgetMode ? setIsFlowNotFound.setTrue() : navigate(Route.NotFound)
          return
        }

        if (!fetchedFlow?.views?.length) {
          throw new Error(UNABLE_LOAD_FLOW_TEXT)
        }

        setIsFetching.setFalse()

        setFlow(fetchedFlow)

        const startView =
          fetchedFlow.views.find(view => view.id === fetchedFlow.currentViewId) ??
          fetchedFlow.views[0]

        setFlowView(startView.id, fetchedFlow.views)

        if (!isWidgetMode) {
          document.title = `${fetchedFlow.name} | Revolut`
        }

        const formFlowViews = getFormFlowViews(fetchedFlow)
        if (formFlowViews && !isCameraSupport(formFlowViews)) {
          isWidgetMode ? setIsNoCamera.setTrue() : navigate(Route.NoCamera)
        }
      } catch (err) {
        console.error(err)
        setErrorText(UNABLE_LOAD_FLOW_TEXT)
      }
    }

    fetchFlow()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    api,
    isWidgetMode,
    setIsFetching,
    setIsFlowNotFound,
    setIsNoCamera,
    isCameraSupport,
    flowId,
    setTransition,
    queryString,
  ])

  const amountOfLoadedViews = flow?.views?.length || 0
  const currentViewIndex = flow?.views?.findIndex(view => view.id === currentView?.id)

  // TODO: https://revolut.atlassian.net/browse/FRMBLDR-809
  // We need to add proper typing to the library
  // it turned out that static forms in biz web app has own submitFlow implementation
  // and it expects custom payload in onFlowComplete callback
  // static forms in biz web - https://bitbucket.org/revolut/revolut-biz-frontend/src/master/src/domains/User/domains/AccessRecovery/hooks/useAccessRecovery.ts#lines-47
  const completeFlow = useCallback(
    (value?: any) => {
      if (isWidgetMode && onFlowComplete) {
        onFlowComplete(value)
        return
      }
      navigateToWebsite()
    },
    [isWidgetMode, onFlowComplete],
  )

  const moveToView = useCallback(
    async (viewId: FormView['id']) => {
      if (!flow || (!currentView && !currentPrompt)) {
        return
      }

      setViewDataFetching(true)

      try {
        if (isFlowDone) {
          completeFlow()
          return
        }

        isWidgetMode && setViewDataFetching(true)
        const response = await validateAndSubmitFlow({ ...flow, currentViewId: viewId })
        isWidgetMode && setViewDataFetching(false)

        // Business web: Handling "destination "chat" form completion
        if (isBusinessWebChatTicketIdResponse(response)) {
          completeFlow({ id: response.id })
          return
        }

        // Business web: Handling static form response
        if (isBusinessWebStaticFormResponse(response)) {
          completeFlow({ response: response.response })
          return
        }

        // Retail web: Handling "destination: chat" form completion
        if (response.state === FlowState.Complete && response.attributes?.chatTicketId) {
          completeFlow({ id: response.attributes.chatTicketId })
          return
        }

        setFlow(response)

        if (response.views?.length) {
          const next =
            response?.views?.find((view: Flow) => response.currentViewId === view.id) ??
            response?.views?.[0]

          setFlowView(next.id, response.views)
          historyActions.push(viewId)
          return
        }

        if (response.state === FlowState.Complete) {
          completeFlow()
        }
      } catch (err) {
        console.error(err)
        setErrorText(flow?.attributes?.failedSubmissionMessage ?? 'Something went wrong')
      } finally {
        setViewDataFetching(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      api,
      completeFlow,
      currentView,
      flow,
      historyActions,
      isFlowDone,
      isFlowDoneActions,
      isWidgetMode,
    ],
  )

  const moveToTheNextView = useCallback(() => {
    const currentViewId = currentPrompt ? currentPrompt.id : currentView?.id
    if (currentViewId) {
      setTransition()
      moveToView(currentViewId)
    }
  }, [currentView, currentPrompt, moveToView, setTransition])

  const moveToThePreviousView = useCallback(() => {
    const previousViewId =
      (currentView?.type === FlowViewType.Form && currentView.previousViewId) ||
      last(history)

    if (previousViewId) {
      setTransition()
      if (!flow?.views) {
        return
      }
      setFlowView(previousViewId, flow?.views)
      history.pop()
    } else if (onBackButtonClick) {
      onBackButtonClick()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, currentView, onBackButtonClick, setTransition, flow])

  const selectViewAction = useCallback(
    (actionId: string) => {
      const view = currentPrompt || currentView
      if (!view || view.type === FlowViewType.StatusDialog) return

      setFlow(currentFlow => {
        let selectedAction: Action | null = null
        const actions = view.actions

        if (view.type === FlowViewType.Form && view.headerAction) {
          actions.push(view.headerAction)
        }

        actions.forEach(action => {
          if (action.id === actionId) {
            selectedAction = action
            action.selected = true
          } else {
            action.selected = false
          }
        })

        // - If non-primary action was selected, then no item values should be submitted
        // - If a single selection item was selected, we cannot have a selected action at the same time
        if (view?.type === FlowViewType.Form && view?.items) {
          getDynamicItems(view.items).forEach(item => {
            if (
              item.value &&
              (view?.actions[0] !== selectedAction || isItemInContinueButtonMode(item))
            ) {
              delete item.value
            }
          })
        }

        return currentFlow
      })
    },
    [currentView, currentPrompt],
  )

  const changeViewItemValues = useCallback(
    (newValues: { [itemId: string]: FlowViewItemDynamic['value'] }) => {
      if (!currentView?.id) return
      if (currentView.type !== FlowViewType.Form) return

      // If a single selection item was selected, we cannot have a selected action at the same time
      const shouldClearActionSelection = Object.keys(newValues).some(itemId =>
        isItemInContinueButtonMode(currentView.items.find(item => item.id === itemId)),
      )

      const changeItemValue = (
        viewItem: FlowViewItemDynamic,
        value: FlowViewItemDynamic['value'],
      ): FlowViewItemDynamic => ({ ...viewItem, value } as FlowViewItemDynamic)

      const changeViewItemValue = (
        view: FormView,
        newValues: Record<string, FlowViewItemDynamic['value']>,
      ): FormView => ({
        ...view,
        headerAction:
          view.headerAction && shouldClearActionSelection
            ? { ...view.headerAction, selected: false }
            : view.headerAction,
        actions: shouldClearActionSelection
          ? view.actions.map(action => ({ ...action, selected: false }))
          : view.actions,
        items: view.items.map(item =>
          isViewItemDynamic(item) && has(newValues, item.id)
            ? changeItemValue(item, newValues[item.id])
            : item,
        ),
      })

      const updatedCurrentView = changeViewItemValue(currentView, newValues)
      setCurrentView(updatedCurrentView)
      setFlow((currentFlow): Flow | undefined => {
        if (!currentFlow) return undefined

        return {
          ...currentFlow,
          views: currentFlow?.views?.map(view =>
            view.id === currentView.id && view.type === FlowViewType.Form
              ? updatedCurrentView
              : view,
          ),
        }
      })
    },
    [currentView],
  )

  const hideCurrentPrompt = () => setCurrentPrompt(null)

  return useMemo(
    () => [
      {
        amountOfLoadedViews,
        currentView,
        currentPrompt,
        hasPreviousView: Boolean(
          currentView?.type === FlowViewType.Form && currentView.previousViewId,
        ),
        currentViewIndex,
        errorText,
        statusDialogView,
        history,
        isFetching,
        isFlowDone,
        isFlowNotFound,
        isNoCamera,
        isTransition,
        isViewDataFetching,
      },
      {
        selectViewAction,
        changeViewItemValues,
        moveToTheNextView,
        moveToThePreviousView,
        setViewDataFetching,
        hideCurrentPrompt,
        abortFlow: onFlowAbort ?? navigateToWebsite,
      },
    ],
    [
      amountOfLoadedViews,
      selectViewAction,
      changeViewItemValues,
      currentView,
      currentPrompt,
      currentViewIndex,
      errorText,
      history,
      isFetching,
      isFlowDone,
      isFlowNotFound,
      isNoCamera,
      isTransition,
      isViewDataFetching,
      moveToTheNextView,
      moveToThePreviousView,
      statusDialogView,
      onFlowAbort,
    ],
  )
}
