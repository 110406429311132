import React from 'react'
import { StatusPopup, Button } from '@revolut/ui-kit'
import { StatusDialogView } from 'types'

import { useFollowLink } from '../useFollowLink'

const SuccessDialog = ({
  statusDialogView,
  onDialogClose,
}: {
  statusDialogView: StatusDialogView
  onDialogClose: () => void
}) => {
  const followLink = useFollowLink()
  const url = statusDialogView?.dialogActionHyperlink

  const onActionClick = () => {
    if (url) {
      followLink(url)
    }
  }

  return (
    <StatusPopup variant="success-optional" open onClose={onDialogClose}>
      <StatusPopup.Title>
        {statusDialogView?.title || 'Thank you for your submission'}
      </StatusPopup.Title>
      <StatusPopup.Description>
        {statusDialogView?.description || ''}
      </StatusPopup.Description>
      {statusDialogView?.dialogActionCaption && url && (
        <StatusPopup.Actions>
          <Button variant="secondary" onClick={onActionClick}>
            {statusDialogView.dialogActionCaption}
          </Button>
        </StatusPopup.Actions>
      )}
    </StatusPopup>
  )
}

export default SuccessDialog
