import React, { useEffect, useState } from 'react'
import {
  Box,
  Group,
  Search,
  CheckboxGroup,
  ItemSkeleton,
  VStack,
  Subheader,
} from '@revolut/ui-kit'
import { useDebounce } from '@react-hook/debounce'

import { TransactionInputItem, TransactionsValue } from 'types'
import { useMoveToTheNextView } from '../../../providers'
import { useTransactions } from './useTransactions'
import { ItemIntersectionSkeleton } from './ItemIntersectionSkeleton'
import { TransactionItem } from '../common/TransactionItem'
import { usePreselectedTransactions } from './usePreselectedTransactions'

export const SPINNER_TESTID = 'spinner-testid'
const DEBOUNCED_SEARCH_TIMEOUT = 300

type Props = TransactionInputItem & {
  disabled: boolean
  changeValue: (value: TransactionsValue) => void
}

const TransactionInput = ({
  id: itemId,
  disabled,
  value,
  searchHint,
  changeValue,
  maxSelected,
  preselectedTransactionId,
  style,
}: Props) => {
  const moveToTheNextView = useMoveToTheNextView()

  const [isMoveToTheNextViewPending, setIsMoveToTheNextViewPending] = useState(false)
  const [searchValue, setSearchValue] = useDebounce('', DEBOUNCED_SEARCH_TIMEOUT)
  const [searchInputValue, setSearchInputValue] = useState(searchValue)
  const [isPreselectedInitialized, setIsPreselectedInitialized] = useState<boolean>(false)
  let preselectedTransactionIds: string[] = []

  if (preselectedTransactionId) {
    preselectedTransactionIds = Array.isArray(preselectedTransactionId)
      ? preselectedTransactionId
      : [preselectedTransactionId]

    // TODO: Remove when BE will stop responding with template string if no query param is present.
    preselectedTransactionIds = preselectedTransactionIds.filter(id => !id.includes('$'))
  }

  const { preselectedTransactions } = usePreselectedTransactions(
    itemId,
    preselectedTransactionIds,
  )

  const {
    transactions,
    transactionsGroupedByDate,
    fetchNextTransactionsPage,
    isNextTransactionsPageFetching,
    isTransactionsFetching,
    hasNextPage,
  } = useTransactions(itemId, searchValue, preselectedTransactionIds)

  useEffect(() => {
    if (
      !value?.transactions?.length &&
      !isPreselectedInitialized &&
      preselectedTransactions.length
    ) {
      changeValue({ transactions: preselectedTransactions })
      setIsPreselectedInitialized(true)
    }
  }, [changeValue, isPreselectedInitialized, preselectedTransactions, value])

  useEffect(() => {
    // Do move after the state got updated.
    if (isMoveToTheNextViewPending) {
      moveToTheNextView()
      setIsMoveToTheNextViewPending(false)
    }
  }, [isMoveToTheNextViewPending, moveToTheNextView])

  const isDisplayIntersectionSkeleton = hasNextPage && !isNextTransactionsPageFetching
  const isContinueButtonStyle = style === 'SINGLE_SELECTION'

  const handleContinueStyleItemClick = (id: string) => {
    const selectedTransaction = transactions.find(transaction => transaction.id === id)
    changeValue({
      transactions: selectedTransaction ? [selectedTransaction] : [],
    })
    setIsMoveToTheNextViewPending(true)
  }

  return (
    <>
      <Box mb="s-16">
        <Search
          value={searchInputValue}
          placeholder={searchHint || 'Search transaction'}
          onChange={searchVal => {
            setSearchValue(searchVal)
            setSearchInputValue(searchVal)
          }}
        />
      </Box>
      {isContinueButtonStyle ? (
        <VStack space="s-16">
          {Object.entries(transactionsGroupedByDate).map(([date, items]) => (
            <VStack key={date}>
              <Subheader>
                <Subheader.Title>{date}</Subheader.Title>
              </Subheader>
              <Group>
                {items.map(transaction => (
                  <TransactionItem
                    key={transaction.id}
                    use="label"
                    transaction={transaction}
                    isContinueButtonStyle={isContinueButtonStyle}
                    handleClick={handleContinueStyleItemClick}
                  />
                ))}
              </Group>
            </VStack>
          ))}
        </VStack>
      ) : (
        <CheckboxGroup
          value={value?.transactions?.map(({ id }) => id) ?? []}
          disabled={disabled}
          onChange={ids => {
            if (ids.length > maxSelected) return
            changeValue({
              transactions: [
                ...preselectedTransactions,
                ...transactions,
              ].filter(({ id }) => ids.includes(id)),
            })
          }}
        >
          {group => (
            <VStack space="s-16">
              <Group>
                {preselectedTransactions.map(transaction => (
                  <TransactionItem
                    key={transaction.id}
                    use="label"
                    transaction={transaction}
                    checkboxGroupInputProps={group.getInputProps({
                      value: transaction.id,
                    })}
                  />
                ))}
              </Group>
              {Object.entries(transactionsGroupedByDate).map(([date, items]) => (
                <VStack key={date}>
                  <Subheader>
                    <Subheader.Title>{date}</Subheader.Title>
                  </Subheader>
                  <Group>
                    {items.map(transaction => (
                      <TransactionItem
                        key={transaction.id}
                        use="label"
                        transaction={transaction}
                        checkboxGroupInputProps={group.getInputProps({
                          value: transaction.id,
                        })}
                      />
                    ))}
                  </Group>
                </VStack>
              ))}
            </VStack>
          )}
        </CheckboxGroup>
      )}
      {isDisplayIntersectionSkeleton && (
        <ItemIntersectionSkeleton onIntersect={fetchNextTransactionsPage} />
      )}
      {isTransactionsFetching && <ItemSkeleton />}
    </>
  )
}

export default TransactionInput
